body {
  background-color: #3c4160;
  background: #3c4160;
  }

.footer-nav {
  padding: 30px 0;
  background: #555;
  color: #fff;}

@import "_overrides";