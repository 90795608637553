#wb-bar {
    background: #273b44;
    @media screen and (max-width: 991px) {
        background: #EAEBED;
    }
}
#wb-bc ol{
    background: #f9f9f9;
}
#wb-bnr {
    background: #fff;
    &.splash{
        background-color: #2F4E5C;
        background: #2F4E5C;
    }
}
#wb-sttl {
    padding: 10px 15px;
    a {
        color: #273b44;
        text-shadow: none;
    }
}
#wb-sm {
    background: #3c4160;
    .menu>li>a.wb-navcurr,
    .sm.open li a.wb-navcurr,
    .sm.open li a:focus,
    .sm.open li a:hover,
    .sm.open li summary.wb-navcurr,
    .sm.open li summary:focus,
    .sm.open li summary:hover {
        background: #273b44;
        color: #fff;
    }
    .menu{
        text-shadow: none;
        float: right;
        >li{
            >a{
                &:focus{
                    color: #000;
                }
            }
        }
    }
    .sm.open{
        border-bottom: 5px solid #fdb813;
    }
}
// main menu caret rotate
.wb-menu .sm-open .expicon{
    transform: rotate(180deg);
    z-index: 1;
}
#wb-sec h3 {
    border-bottom: 3px solid #fdb813;
}

// Share this page edit
.wb-share {
    margin: 2em 0;
}
//
.view-headlines-front-page {
    .item-list {
        ul {
            list-style: none;
            padding: 0;
            li {
                margin-bottom: 16px;
                h2 {
                    margin-top: 0;
                    font-size: 18px;
                }
            }
        }
    }
}
#node-edit {
    #edit-title {
        font-size: 20px;
    }
}
.region-inner {
    .pane-node-field-contact-information,
    .panels-ipe-portlet-wrapper {
        margin: 1.5em 0;
    }
}
.dropdown:hover .dropdown-menu {
    display: block;
}
// Mobile menu sidebar remove
#mb-pnl .sec-pnl{
    @media screen and (max-width: 991px) {
        display: none;
        visibility: hidden;
    }
}
// Motor Vehicles: Vehicle registration renewal
.mv-renew {
  h4.subtitle {
    font-weight: normal;
    margin-top: -15px;
  }
  // Fix Date API's date_select, which't style like other Bootstrap elements.
  .container-inline-date .date-padding {
    float: none;
  }
  .container-inline-date {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

// Make Drupal field help text larger.
//
// Overrides
// /profiles/wetkit/themes/bootstrap/css/3.3.5/overrides.min.css
// which is generated from:
// /profiles/wetkit/themes/bootstrap/starterkits/less/less/overrides.less
div.help-block {
  font-size: 16px;
}

// Remove the border around Date select groups
.form-type-date div.form-control {
  border: none;
  box-shadow: none;
  padding-left: 0;
}

.pane-content{
    h1,h2,h3{
        margin-top: 0.45em;
    }
}
// Splash page
.splash-heading-img{
    padding-left: 0;
    padding-right: 0;
}
.splash-img{
    margin: 0 auto;
}

// home nav
#mini-panel-homepage_featured_links{
    .nav>li>a{
        display: inline;
        padding: 0px;
        border-bottom: 1px solid #295376;
        &:hover, &:focus{
            background-color: transparent;
        }
    }
    ul.nav{
        margin-top: 0;
        margin-bottom: 11.5px;
        display: block;
        list-style-type: disc;
        list-style: inherit;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        padding-left: 40px;
        li{
            display: list-item;
            text-align: -webkit-match-parent;
            &.leaf{
                list-style: inherit;
            }
        }
    }
}

// menublock
.menu-block-5{
    li{
        &.expanded{
            &.active-trail ul{
                display: block;
            }
            ul{
                display: none;
                li{
                    &.expanded{
                        &.active-trail ul{
                            display: block;
                        }
                        ul{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// riel layout padding top
.region-riel-top{
    padding-top: 15px;
}

// type0graphy
.h3, h3 {
    margin-top: 15px;
}

// media
.media{
    margin-top: 0;
}

/// Fraçais menu link top header nav
/// ------------------------
li#wet-fullhd-lang {
    display: none;
}
.lng-ofr {
    ul > li:last-child{
        display: none;
    }
}

// FOOTER
// --------  - - ----
.footer-nav{
    padding: 30px 0;
    background: #555;
    ul{
        li{
            margin-bottom: 0;
            a{
                color: #fff;
                padding: 0;
                font-weight: 300;
                &:visited{
                    color: #fff;
                }
                &:hover{
                    text-decoration: underline;
                }
                &:focus{
                    background: transparent;
                }
            }
        }
    }
}
#wb-info {
    .nav>li>a{
        &:hover{
            background-color: transparent;
            text-decoration: underline;
        }
    }
}

// Feedback form
.feedback.ctools-collapsible-container,
.feedback{
    h2{
        background: transparent;
        border-bottom: 1px solid #ccc;
        padding-top: .5em;
        font-size: 1.25em;
        font-weight: 400;
        cursor: pointer;
    }
    .ctools-toggle{
        height: 27px;
        background-position: 7px 14px;
    }
    form{
        padding: 1em 0 0;
    }
}
.webform-submission-info-text {
    overflow: inherit;
}

//contact section
.contact-hr {
    border-bottom: 1px #ccc solid;
    margin: 0 0 1em;
}
.bean-contact{
    .field{
        margin-bottom: 0.5em;
    }
}

//EVENTS
.view-id-upcoming_events{
    .item-list ul{
        list-style: none;
        padding-left: 0;
    }
}

.ctools-collapsible-container .ctools-collapsible-handle {
  cursor: pointer;
}

// ------------------
// Courts overrides
// ------------------
.webform-component--email-address-fieldset,
.webform-component--telephone-fieldset,
.webform-component--organization-name-fieldset{
  padding: 11.5px 23px;
  margin: 0 0 23px;
  border-left: 5px solid #eee;
  border-right: none;
  border-top: none;
  border-bottom: none;
  box-shadow: none;
  border-radius: 0;
}
// courts weird ie11 icon thing
.alert>:first-child {
  padding-left: 1.2em;
  margin-top: auto;
  margin-left: 0px;
}